import React from 'react';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';

import FeatureContainer from '../../components/FeatureContainer';
import { signUp } from '../../utils/contentProvider/function';
import LoginWithGoogle from '../../components/LoginWithGoogle';
import TrustedBy from '../../components/TrustedBy';
import TestimonialsSlider from '../../components/TestimonialsSlider/TestimonialsSlider';
import 'styles/bug-tracking.scss';
import 'styles/usecases.scss';
import NewFaq from '../../components/NewFaq/NewFaq';

const reviewWebAppFaq = [
  {
    q: 'When can I use the web app project?',
    a: "Our web app project is useful to review all sorts of logged-in applications, dashboards and password-gated websites, so we'd definitely recommend it if you create or review these.",
  },
  {
    q: 'Can I create a web app project on a free plan?',
    a: 'Yes, but it’ll only have a 2-day trial period on the free plan, post which the project will expire and you’ll have to upgrade to view the expired project.',
  },
  {
    q: 'How do I add my web application to ruttl?',
    a: (
      <>
        You need to&nbsp;
        <a
          href="https://chrome.google.com/webstore/detail/ruttl/doecfodblgfnjmmphbedjgllipnbgbld"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;download our Chrome extension&nbsp;
        </a>
        and sign-in to your account. Once that&apos;s done, open your logged-in
        screen/web app, click on our extension, choose to add it in your
        preferred project and your web app will be added to ruttl.
      </>
    ),
  },
  {
    q: 'Do web app projects support edit mode as well?',
    a: 'Yes, our edit mode can be fully used on our web app projects.',
  },
  {
    q: 'Is versioning available on web app projects?',
    a: 'Versioning cannot be availed on our web app projects.',
  },
  {
    q: 'Can I add video comments and attachments inside web app projects?',
    a: 'Yes, you can add both video comments and attachments to our web app projects.',
  },
  // {
  //   id: nanoid(),
  //   q: 'I see a trial period while reviewing web apps. Till when is this trial period valid? What happens after that?',
  //   a: (
  //     <>
  //       Our web app support is on a trial period for everyone till
  //       15th June 2022. Post this, our web app support shall only
  //       be supported for our Pro plan users. Check out our pricing
  //       <Link
  //         to="/pricing/"
  //         target="_blank"
  //         rel="noopener noreferrer">
  //         &nbsp;here
  //       </Link>
  //       .
  //     </>
  //   ),
  // },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));
const WebApps = ({ location }) => (
  <Layout location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title="Review Web Apps | ruttl"
          description="Streamline web application review process. Collect feedback on logged-in screens, utilize video comments, and edit web elements effortlessly."
          url="https://ruttl.com/features/review-web-applications/"
        />

        <main
          className="styled-main"
          style={{
            transition: 'opacity 0.1s',
            transitionDelay: '0.5s',
            opacity: 1,
          }}>
          <section className=" features-hero">
            <div className="container">
              <div className="index-hero__content">
                <h1
                  className="reveal-1"
                  style={{ maxWidth: 760, marginBottom: 25 }}>
                  {/* Report&nbsp;
                  <span className="blue">bugs</span>
                  &nbsp;in seconds */}
                  Review Web Applications
                </h1>
                <p className="index-hero--subhead">
                  Collect feedback on your logged-in screens and dashboards and
                  ship your products faster.
                </p>
                <div
                  className="reveal-1 button-container"
                  style={{ marginBottom: 30 }}>
                  <LoginWithGoogle />

                  <div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button button-white"
                      style={{
                        height: 54,
                        paddingInline: 35,
                      }}
                      href="https://chrome.google.com/webstore/detail/ruttl/doecfodblgfnjmmphbedjgllipnbgbld">
                      Add to chrome
                      <img
                        src="/assets/img/chrome-icon.svg"
                        style={{ width: 24, marginLeft: 5 }}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <TrustedBy />
            </div>
          </section>
          <section className=" container">
            <div className="feature-banner">
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  title="how to review webapps on ruttl"
                  src="https://player.vimeo.com/video/719737253?h=4044e80b2f&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </section>
        </main>
        <section className="usecases-styled-main">
          <div className="styled-bug-tracking ">
            <div className="container">
              <h2 className="center">How it works</h2>
              <div className="flex-2 flex flex-wrap align-items-stretch">
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block how-it-works-card">
                      <div>
                        <img
                          loading="lazy"
                          src="/assets/img/chrome-extension.png"
                          alt="ruttl chrome extension ui shot"
                          title="ruttl chrome extension ui shot"
                        />
                      </div>
                      <h4>1. Download Chrome Extension</h4>
                      <p>
                        Install ruttl&apos;s chrome extension and visit any page
                        inside your web app, that needs to be reviewed.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block how-it-works-card">
                      <div>
                        <img
                          loading="lazy"
                          src="/assets/img/add-web-page.png"
                          alt="ruttl add a webpage to project ui shot"
                          title="ruttl add a webpage to project ui shot"
                        />
                      </div>
                      <h4>2. Add Page to Project</h4>
                      <p>
                        Select the page inside your application, click on the
                        ruttl extension and add it to the project of your
                        choice. The page will be ready to get reviewed inside
                        your dashboard.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block how-it-works-card">
                      <div>
                        <img
                          loading="lazy"
                          src="/assets/img/add-to-project.png"
                          alt="ruttl interface ui shot"
                          title="ruttl interface ui shot"
                        />
                      </div>
                      <h4>3. Work with your team</h4>
                      <p>
                        Add your teammates to the project and start commenting
                        directly on your live web app page.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="styled-edit">
            <div className="section features-main index-edit" id="features">
              <div className="container">
                <FeatureContainer>
                  <div className="feature-set-single">
                    <div className="flex flex-wrap align-items-center">
                      <div className="feature-img">
                        <div className="feature-img-bg">
                          <img
                            loading="lazy"
                            src="/assets/img/leave-comments-website.jpg"
                            alt="illustration of leaving comment on live website"
                            title="illustration of leaving comment on live website"
                          />
                        </div>
                      </div>
                      <div className="feature-txt">
                        <h2 className="feature-head">
                          Collect better feedback with visual commenting
                        </h2>
                        <p>
                          You can instantly collect or leave feedback on any web
                          page that you are developing by directly commenting on
                          it. ruttl supports all types of applications,
                          including those that have login screens or
                          password-gated.
                        </p>
                        <button
                          type="button"
                          onClick={() => signUp(toggleSignup)}
                          className="button">
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </FeatureContainer>
                <FeatureContainer>
                  <div className="feature-set-single">
                    <div className="flex flex-wrap align-items-center ">
                      <div className="feature-img">
                        <div className="feature-img-bg">
                          {/* <video
                          src="/assets/img/upload-image.mp4"
                          autoPlay
                          muted
                          loop
                          playsInline></video> */}
                          <img
                            loading="lazy"
                            src="/assets/img/leave-video-comments.png"
                            alt="ruttl activity panel ui shot"
                            title="ruttl activity panel ui shot"
                          />
                        </div>
                      </div>
                      <div className="feature-txt">
                        <h2 className="feature-head">
                          Upload Video Comments &amp; Attachments
                        </h2>
                        <p>
                          Along with your visual comments, you also have the
                          option to attach screen recording, reference images
                          and screenshots. This can help you share much more
                          precise feedback and ensure faster turnaround times on
                          your project.
                        </p>
                        <button
                          type="button"
                          onClick={() => signUp(toggleSignup)}
                          className="button">
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </FeatureContainer>
                <FeatureContainer>
                  <div className="feature-set-single">
                    <div className="flex flex-wrap align-items-center ">
                      <div className="feature-img">
                        <div className="feature-img-bg">
                          <img
                            loading="lazy"
                            src="/assets/img/show-activities.png"
                            alt="ruttl inspect css panel ui shot"
                            title="ruttl inspect css panel ui shot"
                          />
                        </div>
                      </div>
                      <div
                        className="feature-txt"
                        style={{ transform: ' translateY(-30px)' }}>
                        <h2 className="feature-head">
                          Edit Anything On Your Web App Pages
                        </h2>
                        <p>
                          With ruttl&apos;s edit mode, you can directly edit any
                          element on inside your web app and modify the CSS.
                          Properties like Fonts, Opacity, Color, Size, Spacing,
                          Alignment can be easily adjusted, so that your
                          developers get perfect design change values.
                        </p>
                        <button
                          type="button"
                          onClick={() => signUp(toggleSignup)}
                          className="button">
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </FeatureContainer>
                <FeatureContainer>
                  <div className="feature-set-single">
                    <div className="flex flex-wrap align-items-center ">
                      <div className="feature-img">
                        <div className="feature-img-bg">
                          <img
                            loading="lazy"
                            src="/assets/img/stay-up-to-date.jpg"
                            alt="ruttl notifications panel ui shot"
                            title="ruttl notifications panel ui shot"
                          />
                        </div>
                      </div>
                      <div className="feature-txt">
                        <h2 className="feature-head">
                          Stay up to date with notifications
                        </h2>
                        <p>
                          Make sure that you are always in the loop by getting
                          notified on every single project update. ruttl will
                          send you custom notifications whenever you are tagged
                          by other team mate, ticket status is changed or if the
                          resolution deadline of a ticket is nearby. This way,
                          you can focus on the workflow itself and let ruttl
                          keep track of all your bug queries.
                        </p>
                        <button
                          type="button"
                          onClick={() => signUp(toggleSignup)}
                          className="button">
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                </FeatureContainer>
              </div>
            </div>
          </div>
          <TestimonialsSlider />
          <div className="section-gap">
            <div className="container">
              <div className="footer-cta-block">
                <h2>ruttl integrates with your favorite software</h2>

                <Link href="/integrations/" className="button">
                  View all Integrations
                </Link>
              </div>
            </div>
          </div>
          <GetStartedCenter toggleSignup={toggleSignup} />

          <NewFaq data={reviewWebAppFaq} />
        </section>
      </>
    )}
  </Layout>
);

WebApps.propTypes = {
  location: PropTypes.object,
};

export default WebApps;
